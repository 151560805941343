.card {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #343a40;
    color: #bbb;
    width: 400px;
    height: 80px;
    padding: 15px;
    margin-bottom: 10px;
}

.cardLabel {
    width: 100%;
    height: 100%;
    color: rgb(3, 186, 252);
    font-size: 24px;
    text-align: center;
    vertical-align: middle;
    margin-top: 4px;
}

.card:hover {
    cursor: pointer;
}

a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }