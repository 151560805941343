table td.response {
    font-size: smaller;
    min-width: 40%;
    max-width: 70%;
}

table td.copyable {
    font-family: monospace;
}

table td.copyable:hover {
    cursor: pointer;
    color: var(--blue);
}

.clipboard {
    margin-left: 5px;
    margin-top: -2px;
}

.clipboard:hover {
    color: var(--blue);
}

.requestURL {
    font-family: monospace;
}