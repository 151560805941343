.container {
    max-width: 1500px;
}

div.trainingPacks {
    margin-bottom: 50px;
}

table.trainingPacks {
    width: 100%;
}

table.trainingPacks th:first-child {
    width: 200px;
}

table.trainingPacks th, td {
    border: 1px solid black;
    padding: 0 10px;
}

table.trainingPacks td:first-child {
    white-space: nowrap;
}

.clipboard {
    color: grey;
    margin-right: 10px;
    margin-top: -2px;
}

.clipboard:hover {
    cursor: pointer;
    color: black;
}

#flashMessageDiv {
    padding: 5px;
    border-radius: 6px;
    background: rgba(128, 128, 128, 0.7);
    position: absolute;
    z-index: 1000;
}

.searchForm label {
    padding-right: 5px;
}

.searchForm input {
    margin-left: 15px;
}

.searchForm input[type=text] {
    width: 500px;
}