.card {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #343a40;
    color: #bbb;
    width: 400px;
    height: 80px;
    padding: 15px;
    margin-bottom: 10px;
}

img.profile {
    display: inline;
    float: left;
    height: 50px;
    object-fit: contain;
}

.info {
    display: flex;
    flex-flow: row nowrap;
}

.textInfo {
    padding-left: 20px;
}

.logout {
    height: 50px;
    vertical-align: middle;
    color: var(--red);
}

.logout:hover {
    cursor: pointer;
}

.highlight {
    color: rgb(3, 186, 252);
}

.login {
    width: 100%;
    height: 100%;
    color: rgb(3, 186, 252);
    font-size: 24px;
    text-align: center;
    vertical-align: middle;
    margin-top: 4px;
}

.login:hover {
    cursor: pointer;
}

.loading {
    width: 100%;
    height: 100%;
    font-size: 24px;
    text-align: center;
    vertical-align: middle;
    margin-top: 4px;
}